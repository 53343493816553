import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  // Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
// import SelectField from 'material-ui/SelectField';

// // import SelectField from 'material-ui/SelectField';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
import Multiselect from 'multiselect-react-dropdown';
import Select2 from 'react-select';


// import ListItemText from '@mui/material/ListItemText';

import CloseIcon from "@material-ui/icons/Close";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button } from "../../../../components/Wrappers/Wrappers";



import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
export default function AssignWorkerDialog(props) {
  let {
    classes,
    data,
    updateformData,
    workerAssigningCloseModal,
    items,
    // updatehandleChangeInput,
    // items,
    workerItems,
    assignWorkerForJobSubmit,
    handleAssignWorkerDropDown,
    assignWorkerModal
  } = props;

  // const [personName, setPersonName] = React.useState<string[]>([]);


  // console.log("101010101.........................__________", items);
  // console.log("MODAL___assignLandlordModal", assignWorkerForJobSubmit);
  // console.log("MODAL___updateformData", updateformData);

  let colourOptions = []
  if (workerItems && workerItems.length > 0) {
    workerItems.forEach((emp) => {
      colourOptions.push(
        {
          value: emp.first_name + " " + emp.last_name,
          label: emp.first_name + " " + emp.last_name,
          id: emp.id,
        }
      )
    })
  }
  // console.log("MODAL______colourOptions::", colourOptions);

  return (
    // <Dialog open={assignWorkerModal} onClose={workerAssigningCloseModal}>
    //   <AppBar className={classes.appBar}>
    //     <Toolbar>
    //       <IconButton
    //         edge="start"
    //         color="inherit"
    //         onClick={workerAssigningCloseModal}
    //         aria-label="close"
    //       >
    //         <CloseIcon />
    //       </IconButton>
    //       <Typography variant="h6" className={classes.title}>
    //         Assign Worker
    //       </Typography>
    //       <Button color="inherit" onClick={workerAssigningCloseModal}>
    //         Cancel
    //       </Button>
    //     </Toolbar>
    //   </AppBar>

    //   <Container component="main" maxWidth="xs">
    //     <CssBaseline />
    //     <div className={classes.paper}>
    //       <Typography component="h1" variant="h5">
    //         Assign Worker
    //       </Typography>
    //       <ValidatorForm onSubmit={assignWorkerForJobSubmit}>
    //         <Grid container spacing={2} style={{ width: '500px' }}>
    //           <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
    //             <label htmlFor="resourceList" style={{ color: 'grey' }}>Worker List</label>

    //             <Select2
    //               id="workerList"
    //               isMulti
    //               width="full"
    //               name="workerList"
    //               onChange={handleAssignWorkerDropDown}
    //               options={colourOptions}
    //               className="basic-multi-select width: 'full'"
    //               classNamePrefix="Activities"
    //             />
    //           </Grid>
    //           <div style={{ margin: "7px" }}></div>

    //           <Grid item xs={12}></Grid>
    //         </Grid>
    //         <Button
    //           fullWidth
    //           variant="contained"
    //           color="primary"
    //           type="submit"
    //           className={classes.submit}
    //         >
    //           Save
    //         </Button>
    //       </ValidatorForm>

    //     </div>
    //   </Container>
    // </Dialog>
    // <Dialog open={assignWorkerModal} onClose={workerAssigningCloseModal}>

    //   <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
    //     <CloseIcon onClick={workerAssigningCloseModal} style={{ backgroundColor: "#8093FE", padding: "4px", cursor: "pointer", transition: "background-color 0.3s", color: "#fff", borderRadius: "100%" }} />
    //   </div>

    //   <Container component="main" maxWidth="md">
    //     <div className={classes.paper}>
    //       <Typography component="h1" variant="h5">
    //         Assign Worker
    //       </Typography>
    //       <ValidatorForm onSubmit={assignWorkerForJobSubmit}>
    //         <Grid item xs={12} sm={12}>
    //           <label htmlFor="resourceList" style={{ color: 'grey' }}>Worker List</label>

    //           <Select2
    //             id="workerList"
    //             isMulti
    //             width="full"
    //             name="workerList"
    //             onChange={handleAssignWorkerDropDown}
    //             options={colourOptions}
    //             className="basic-multi-select width: 'full'"
    //             classNamePrefix="Activities"
    //           />
    //         </Grid>

    //         <Button
    //           fullWidth
    //           variant="contained"
    //           color="primary"
    //           type="submit"
    //           className={classes.submit}
    //           style={{ marginTop: "20px" }}
    //         >
    //           Save
    //         </Button>
    //       </ValidatorForm>

    //     </div>
    //   </Container>
    // </Dialog>

    <Dialog open={assignWorkerModal} onClose={workerAssigningCloseModal}>

      <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
        <CloseIcon onClick={workerAssigningCloseModal} style={{ backgroundColor: "#8093FE", padding: "4px", cursor: "pointer", transition: "background-color 0.3s", color: "#fff", borderRadius: "100%" }} />
      </div>
      <DialogTitle>Assign Worker</DialogTitle>
      <DialogContent style={{ width: "100%", minWidth: "360px", height: "100%", overflow: "hidden" }}>
        <ValidatorForm onSubmit={assignWorkerForJobSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <label htmlFor="resourceList" style={{ color: 'grey' }}>Worker List</label>
              <Select2
                id="workerList"
                isMulti
                width="full"
                name="workerList"
                onChange={handleAssignWorkerDropDown}
                options={colourOptions}
                className="basic-multi-select width: 'full'"
                classNamePrefix="Activities"
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            className={classes.submit}
            style={{ marginTop: "20px" }}
          >
            Save
          </Button>
        </ValidatorForm>
      </DialogContent>

      <style>
        {`
      @media (min-width: 600px) {
        .MuiDialogContent-root {
          min-width: 100%;
          width: calc(100% - 32px); /* Adjust padding */
        }
      }

      @media (min-width: 400px) {
        .MuiDialogContent-root {
          min-width: 100%;
          width: calc(100% - 16px); /* Adjust padding */
        }
      }
    `}
      </style>
    </Dialog>




  );
}
