import React, { useState } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  // Container,
  // CssBaseline,
  // Grid,
  Typography,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button } from "../../../../components/Wrappers/Wrappers";
import ViewJobPdfDetails from "../ViewJobPdfDetails/ViewJobPdfDetails";
import floorPlan from "../../../../images/floor-plan.svg";
import roofPlan from "../../../../images/roof-plan.svg";
import Fire from "../../../../images/fire.svg";
import Electrical from "../../../../images/electrical.svg";
import Sprinkler from "../../../../images/sprinkler.svg";
import Boiler from "../../../../images/boiler.svg";
import HVAC from "../../../../images/hvac.svg";

export default function ViewLocationLandlordDialog(props) {
  let {
    classes,
    // formData,
    jobCreateModal,
    siteInfoData,
    handleClose,
    // handleViewClose,
    viewRowData,
    // handleChangeInput,
    // handleSubmit,
    // data
  } = props;
  //console.log("jobCreateModal  ",jobCreateModal);
  // console.log("viewRowData in component  ", viewRowData);
  console.log("siteInfoData in component  ", siteInfoData);
  const [viewDocument, setViewDocument] = useState(false);
  const [viewDocumentName, setViewDocumentName] = useState("");
  const [viewDocumentLink, setViewDocumentLink] = useState([]);
  const handleClosePdf = () => {
    setViewDocument(false);
  };

  return (
    <>
      {/* <Dialog fullScreen open={jobCreateModal} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Location Details
            </Typography>
            <Button color="inherit" onClick={handleClose}>
              Cancel
            </Button>
          </Toolbar>
        </AppBar>

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "0 30px",
            }}
          >
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                <u>Location Details</u>
              </Typography>

              <div className={classes.paper}>
                <Typography
                  style={{ fontSize: "17px" }}
                  component="h1"
                  variant="h5"
                >
                  <b>Address :</b>
                  {viewRowData.line1}
                  {viewRowData.line2}
                  {viewRowData.line3}

                  <br />
                  <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    <b>City :</b> {viewRowData.city}
                  </Box>
                  <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    <b>Postal/Zip Code :</b> {viewRowData.pin}
                  </Box>
                  <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    <b>Province/State :</b> {viewRowData.state}
                  </Box>
                </Typography>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#f6f7f8",
                minHeight: "100vh",
                padding: "100px 30px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <button
                  className={classes.icons}
                  onClick={() => {
                    setViewDocument(true);
                    setViewDocumentLink(siteInfoData && siteInfoData.floorPlansFileLinks ?
                      siteInfoData.floorPlansFileLinks : []);
                    setViewDocumentName(siteInfoData.floorPlans)
                  }}
                >
                  <img src={floorPlan} width="183px" height="" alt=" " />
                </button>
                <button
                  className={(classes.iconSpace, classes.icons)}
                  onClick={() => {
                    setViewDocument(true);
                    setViewDocumentLink(siteInfoData && siteInfoData.roofPlansFileLinks ?
                      siteInfoData.roofPlansFileLinks : []);

                    setViewDocumentName(siteInfoData.roofPlans)
                  }}
                >
                  <img src={roofPlan} width="183px" alt=" " />
                </button >
                <button
                  className={classes.icons}
                  onClick={() => {
                    setViewDocument(true);
                    setViewDocumentLink(siteInfoData && siteInfoData.fireCertificationFileLinks ?
                      siteInfoData.fireCertificationFileLinks : []);
                    setViewDocumentName(siteInfoData.fireCertification)
                  }}
                >
                  <img src={Fire} width="183px" alt=" " />
                </button>
              </div>
              <div>
                <button
                  className={classes.icons}
                  onClick={() => {
                    setViewDocument(true);
                    setViewDocumentLink(siteInfoData && siteInfoData.electricalRoomFileLinks ?
                      siteInfoData.electricalRoomFileLinks : []);
                    setViewDocumentName(siteInfoData.electricalRoom)
                  }}
                >
                  <img src={Electrical} width="183px" height="" alt=" " />
                </button>

                <button
                  className={(classes.iconSpace, classes.icons)}
                  onClick={() => {
                    setViewDocument(true);
                    setViewDocumentLink(siteInfoData && siteInfoData.sprinklerRoomFileLinks ?
                      siteInfoData.sprinklerRoomFileLinks : []);
                    setViewDocumentName(siteInfoData.sprinklerRoom)
                  }}
                >
                  <img src={Sprinkler} width="183px" alt=" " />
                </button>
                <button
                  className={classes.icons}
                  onClick={() => {
                    setViewDocument(true);
                    setViewDocumentLink(siteInfoData && siteInfoData.boilerRoomFileLinks ?
                      siteInfoData.boilerRoomFileLinks : []);
                    setViewDocumentName(siteInfoData.boilerRoom)
                  }}
                >
                  <img src={Boiler} width="183px" alt=" " />
                </button>
              </div>
              <div>
                <button
                  className={classes.icons}
                  onClick={() => {
                    setViewDocument(true);
                    setViewDocumentLink(siteInfoData && siteInfoData.hvacEquipmentFileLinks ?
                      siteInfoData.hvacEquipmentFileLinks : []);
                    setViewDocumentName(siteInfoData.hvacEquipment)
                  }}
                >
                  <img src={HVAC} width="183px" height="" alt=" " />
                </button>
              </div>
            </div>
          </div>
        </div>
        <ViewJobPdfDetails
          show={viewDocument}
          viewDocumentName={viewDocumentName}
          viewDocumentLink={viewDocumentLink}

          handleClosePdf={handleClosePdf} />
      </Dialog> */}


      <Dialog fullScreen open={jobCreateModal} onClose={handleClose}>
        <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
          <CloseIcon onClick={handleClose} style={{ backgroundColor: "#8093FE", padding: "4px", cursor: "pointer", transition: "background-color 0.3s", color: "#fff", borderRadius: "100%" }} />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "10px" }}>
            <div style={{ marginBottom: "20px", width: "100%", maxWidth: "800px", display: "flex", flexWrap: "wrap", justifyContent: "space-around", backgroundColor: "#F6F7FF" }}>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.floorPlansFileLinks ?
                    siteInfoData.floorPlansFileLinks : []);
                  setViewDocumentName(siteInfoData.floorPlans)
                }}
              >
                <img src={floorPlan} width="183px" height="" alt=" " />
              </button>
              <button
                className={(classes.iconSpace, classes.icons)}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.roofPlansFileLinks ?
                    siteInfoData.roofPlansFileLinks : []);

                  setViewDocumentName(siteInfoData.roofPlans)
                }}
              >
                <img src={roofPlan} width="183px" alt=" " />
              </button>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.fireCertificationFileLinks ?
                    siteInfoData.fireCertificationFileLinks : []);
                  setViewDocumentName(siteInfoData.fireCertification)
                }}
              >
                <img src={Fire} width="183px" alt=" " />
              </button >


              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.electricalRoomFileLinks ?
                    siteInfoData.electricalRoomFileLinks : []);
                  setViewDocumentName(siteInfoData.electricalRoom)
                }}
              >
                <img src={Electrical} width="183px" height="" alt=" " />
              </button>

              <button
                className={(classes.iconSpace, classes.icons)}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.sprinklerRoomFileLinks ?
                    siteInfoData.sprinklerRoomFileLinks : []);
                  setViewDocumentName(siteInfoData.sprinklerRoom)
                }}
              >
                <img src={Sprinkler} width="183px" alt=" " />
              </button>
              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.boilerRoomFileLinks ?
                    siteInfoData.boilerRoomFileLinks : []);
                  setViewDocumentName(siteInfoData.boilerRoom)
                }}
              >
                <img src={Boiler} width="183px" alt=" " />
              </button>


              <button
                className={classes.icons}
                onClick={() => {
                  setViewDocument(true);
                  setViewDocumentLink(siteInfoData && siteInfoData.hvacEquipmentFileLinks ?
                    siteInfoData.hvacEquipmentFileLinks : []);
                  setViewDocumentName(siteInfoData.hvacEquipment)
                }}
              >
                <img src={HVAC} width="183px" height="" alt=" " />
              </button>
            </div>
            <div className={classes.paper} style={{ width: "100%", maxWidth: "800px" }}>
              <Typography component="h1" variant="h5">
                <u>Job Details</u>
              </Typography>

              <div className={classes.paper}>
                <Typography
                  style={{ fontSize: "17px" }}
                  component="h1"
                  variant="h5"
                >
                  <b>Address :</b>
                  {viewRowData.line1}
                  {viewRowData.line2}
                  {viewRowData.line3}

                  <br />
                  <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    <b>City :</b> {viewRowData.city}
                  </Box>
                  <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    <b>Postal/Zip Code :</b> {viewRowData.pin}
                  </Box>
                  <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    <b>Province/State :</b> {viewRowData.state}
                  </Box>
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <ViewJobPdfDetails
          show={viewDocument}
          viewDocumentName={viewDocumentName}
          viewDocumentLink={viewDocumentLink}
          handleClosePdf={handleClosePdf} />
      </Dialog>
      <style jsx>{`
  .md {
    display: flex;
  }

  /* Define flex direction based on screen size */
  @media (min-width: 768px) {
    /* For medium screens and above */
    .md\:flex-row {
      flex-direction: row;
    }
    .md\:flex-col {
      flex-direction: column;
    }
  }
`}</style>
    </>


  );
}
