import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Table,
  Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
// import SelectField from 'material-ui/SelectField';

// // import SelectField from 'material-ui/SelectField';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
import Multiselect from 'multiselect-react-dropdown';
import Select2 from 'react-select';


// import ListItemText from '@mui/material/ListItemText';

import CloseIcon from "@material-ui/icons/Close";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button } from "../../../../components/Wrappers/Wrappers";

export default function DeleteWorkerDialog(props) {
  let {
    classes,
    workerDeleteCloseModal,
    onDeleteClick,
    viewDeleteData,
    assignWorkerForJobSubmit,
    deleteWorkerModal
  } = props;
  console.log('viewDeleteDataviewDeleteDataviewDeleteData', onDeleteClick)

  return (
    // <Dialog fullScreen open={deleteWorkerModal} onClose={workerDeleteCloseModal}>
    //   <AppBar className={classes.appBar}>
    //     <Toolbar>
    //       <IconButton
    //         edge="start"
    //         color="inherit"
    //         onClick={workerDeleteCloseModal}
    //         aria-label="close"
    //       >
    //         <CloseIcon />
    //       </IconButton>
    //       <Typography variant="h6" className={classes.title}>
    //         Delete Worker
    //       </Typography>
    //       <Button color="inherit" onClick={workerDeleteCloseModal}>
    //         Cancel
    //       </Button>
    //     </Toolbar>
    //   </AppBar>
    //   <Container component="main" maxWidth="xs">
    //     <CssBaseline />
    //     <div className={classes.paper}>
    //       <Typography component="h1" variant="h5">
    //         Delete Worker
    //       </Typography>



    //       <ValidatorForm onSubmit={assignWorkerForJobSubmit}>
    //         <Paper className={classes.root}>
    //           <Table className={classes.table}>
    //             <Table className="mb-0">
    //               <TableHead style={{ background: "#f6f6f6" }}>
    //                 <TableRow>
    //                   <TableCell style={{ width: "10%" }}>S. No.</TableCell>
    //                   <TableCell style={{ width: "15%" }}>Name</TableCell>
    //                   <TableCell style={{ width: "15%" }}>Deletion</TableCell>
    //                 </TableRow>
    //               </TableHead>
    //               <TableBody>
    //                 {
    //                   viewDeleteData && viewDeleteData.assigned_worker && viewDeleteData.assigned_worker.length > 0 ? viewDeleteData.assigned_worker.map((items, index) => (
    //                     <TableRow

    //                     >

    //                       <TableCell className="pl-3 fw-normal">
    //                         {items && items.first_name
    //                           ? items.first_name
    //                           : "-"} {" "}
    //                         {items && items.last_name
    //                           ? items.last_name
    //                           : "-"}
    //                       </TableCell>

    //                       <TableCell style={{ textAlign: "left" }}>
    //                         <Button
    //                           color="primary"
    //                           size="small"
    //                           className="px-2"
    //                           variant="contained"
    //                           style={{ marginRight: "5px" }}
    //                           onClick={() => onDeleteClick(items, viewDeleteData)}
    //                         >
    //                           Delete Worker
    //                         </Button>
    //                       </TableCell>
    //                     </TableRow>
    //                   )) : "not found"
    //                 }

    //               </TableBody>
    //             </Table>
    //           </Table>
    //         </Paper>

    //       </ValidatorForm>

    //     </div>
    //   </Container>
    // </Dialog>


    <Dialog open={deleteWorkerModal} onClose={workerDeleteCloseModal} fullWidth maxWidth="xs" >
      {/* <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={workerDeleteCloseModal} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Delete Worker
          </Typography>
          <Button color="inherit" onClick={workerDeleteCloseModal}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar> */}
      <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
        <CloseIcon onClick={workerDeleteCloseModal} style={{ backgroundColor: "#8093FE", padding: "4px", cursor: "pointer", transition: "background-color 0.3s", color: "#fff", borderRadius: "100%" }} />
      </div>
      <Container component="main" maxWidth="md" >

        <div>
          <Typography component="h1" variant="h5" >
            Delete Worker
          </Typography>
          <ValidatorForm onSubmit={assignWorkerForJobSubmit} style={{ marginBottom: "20px", marginTop: "10px" }}>
            {/* <Paper className={classes.root}> */}
            <Table className={classes.table}>
              <TableHead style={{ background: "#8093FE" }}>
                <TableRow>
                  <TableCell style={{ width: "33.33%", color: "#fff", fontWeight: 700 }}>S. No.</TableCell>
                  <TableCell style={{ width: "33.33%", color: "#fff", fontWeight: 700 }}>Name</TableCell>
                  <TableCell style={{ width: "33.33%", color: "#fff", fontWeight: 700 }}>Deletion</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viewDeleteData && viewDeleteData.assigned_worker && viewDeleteData.assigned_worker.length > 0 ? (
                  viewDeleteData.assigned_worker.map((items, index) => (
                    <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#F2F3F9' : '#ffffff' }}>
                      <TableCell className="pl-3 fw-normal">{index + 1}</TableCell>
                      <TableCell className="pl-3 fw-normal">
                        {items && items.first_name ? items.first_name : "-"} {items && items.last_name ? items.last_name : "-"}
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        <Button
                          color="primary"
                          size="small"
                          className="px-2"
                          variant="contained"
                          style={{ marginRight: "5px" }}
                          onClick={() => onDeleteClick(items, viewDeleteData)}
                        >
                          Delete Worker
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>No workers found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {/* </Paper> */}
          </ValidatorForm>
        </div>
      </Container>
    </Dialog>

  );
}
