import { jobConstants } from '../_constants';
import { jobService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';
export const jobActions = {
    login,
    logout,
    addJob,
    uploadImage,
    disableJob,
    deleteJob,
    updateJob,
    getAllJob,
    getFilteredAssignedJob
};
function login(data) {
    return dispatch => {
        dispatch(request({ data }));
        jobService.login(data)
            .then(
                job => {
                    dispatch(success(job));
                    history.push({ pathname: '#/app/dashboard' });
                    window.location.reload();
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(job) { return { type: jobConstants.LOGIN_REQUEST, job } }
    function success(job) { return { type: jobConstants.LOGIN_SUCCESS, job } }
    function failure(error) { return { type: jobConstants.LOGIN_FAILURE, error } }
}
function logout() {
    jobService.logout();
    return { type: jobConstants.LOGOUT };
}
function getFilteredAssignedJob(data) {
    return dispatch => {
        dispatch(request());
        jobService.getFilteredAssignedJob(data)
            .then(
                jobs => dispatch(success(jobs)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: jobConstants.GETALL_REQUEST } }
    function success(jobs) { return { type: jobConstants.GETALL_SUCCESS, jobs } }
    function failure(error) { return { type: jobConstants.GETALL_FAILURE, error } }
}
function getAllJob(data) {
    return dispatch => {
        dispatch(request());
        jobService.getAllJob(data)
            .then(
                jobs => {
                    //console.log("jobs  ",jobs);
                    let returnResppnse=
                    jobs.getAllJob?jobs.getAllJob.map(job => ({ value: job.id, label: job.firstName+" "+job.lastName })):[];
                    let newRespnse={...jobs,getAllJob:returnResppnse}
                    dispatch(success(newRespnse))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: jobConstants.GET_ALL_JOB_REQUEST } }
    function success(jobs) { return { type: jobConstants.GET_ALL_JOB_SUCCESS, jobs } }
    function failure(error) { return { type: jobConstants.GET_ALL_JOB_FAILURE, error } }
}
function addJob(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        jobService.addJob(data)
            .then(
                jobs => {
                    dispatch(success(jobs));
                    dispatch(this.getFilteredAssignedJob(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: jobConstants.ADD_RESTAURANT_JOB_REQUEST } }
    function success(jobs) { return { type: jobConstants.ADD_RESTAURANT_JOB_SUCCESS, jobs } }
    function failure(error) { return { type: jobConstants.ADD_RESTAURANT_JOB_FAILURE, error } }
}
function updateJob(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        jobService.updateJob(data)
            .then(
                jobs => {
                    dispatch(success(jobs));
                    dispatch(this.getFilteredAssignedJob(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: jobConstants.ADD_RESTAURANT_JOB_REQUEST } }
    function success(jobs) { return { type: jobConstants.ADD_RESTAURANT_JOB_SUCCESS, jobs } }
    function failure(error) { return { type: jobConstants.ADD_RESTAURANT_JOB_FAILURE, error } }
}
function disableJob(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id:data.id
    }
    return dispatch => {
        dispatch(request());
        jobService.disableJob(maintempdata)
            .then(
                jobs => {
                    dispatch(success(jobs));
                    dispatch(this.getFilteredAssignedJob(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: jobConstants.ADD_RESTAURANT_JOB_REQUEST } }
    function success(jobs) { return { type: jobConstants.ADD_RESTAURANT_JOB_SUCCESS, jobs } }
    function failure(error) { return { type: jobConstants.ADD_RESTAURANT_JOB_FAILURE, error } }
}
function deleteJob(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id:data.id
    }
    return dispatch => {
        dispatch(request());
        jobService.deleteJob(maintempdata)
            .then(
                jobs => {
                    dispatch(success(jobs));
                    dispatch(this.getFilteredAssignedJob(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: jobConstants.ADD_RESTAURANT_JOB_REQUEST } }
    function success(jobs) { return { type: jobConstants.ADD_RESTAURANT_JOB_SUCCESS, jobs } }
    function failure(error) { return { type: jobConstants.ADD_RESTAURANT_JOB_FAILURE, error } }
}
function uploadImage(data) {
    return dispatch => {
        jobService.uploadImage(data)
            .then(
                uploadImage => {
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: jobConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: jobConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}
