import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { Button } from "../../../../components/Wrappers";

import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

function TableComponent(props) {
  let {
    data,
    offset,
    classes,
    handleOpenViewCreateModal,
    // onDeleteClick,
    // onDisable
  } = props;
  // var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  // keys.shift(); // delete "id" key

  const getEmail = (data) => {
    let email = "";
    if (data.assinged_IS) {
      email = data.assinged_IS.email;
    } else {
      data.assigned_worker.map((worker) => {
        email = worker.email + " ";
      });
    }
    return email;
  };

  return (
    <Paper className={classes.root} style={{ width: "90" }}>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead style={{ background: "#8093FE" }}>
            <TableRow>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}>S. NO.</TableCell>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}>Job Details</TableCell>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}>Job Title</TableCell>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}>Address</TableCell>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}>City</TableCell>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}>Province/State</TableCell>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }}>Designation</TableCell>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700 }} >Job Type</TableCell>
              <TableCell style={{ width: "10%", color: "#fff", fontWeight: 700, textAlign: "center" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((tempdata, index) => (
              <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#F2F3F9' : '#ffffff' }}>
                <TableCell className="pl-3 fw-normal">
                  {offset + index + 1}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.job_details}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.job_title}
                </TableCell>
                {/* <TableCell className="pl-3 fw-normal">
                  {getEmail(tempdata)}
                </TableCell> */}
                <TableCell className="pl-3 fw-normal">
                  {tempdata.location_id
                    ? `${tempdata.location_id.linecomplete} `
                    : ""}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.location_id && tempdata.location_id.city
                    ? tempdata.location_id.city
                    : "-"}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.location_id && tempdata.location_id.state
                    ? tempdata.location_id.state
                    : "-"}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.designation_Id && tempdata.designation_Id.name
                    ? tempdata.designation_Id.name
                    : "-"}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.jobType_Id && tempdata.jobType_Id.name
                    ? tempdata.jobType_Id.name
                    : "-"}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Button
                    color="success"
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{ marginRight: "5px" }}
                    onClick={() => handleOpenViewCreateModal(tempdata)}
                  >
                    More Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(TableComponent);
