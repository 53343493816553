import React, { Component } from "react";
import {
  Grid,
  //LinearProgress,
  // Select,
  // OutlinedInput,
  // MenuItem,
} from "@material-ui/core";
import { userActions } from "../../_actions";
import { connect } from "react-redux";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
// import GoogleMap from "../../components/GoogleMap";
import { Typography } from "../../components/Wrappers";
import { Box } from '@mui/material';


class Dashboard extends Component {
  constructor(props) {
    document.title = "ATS | Contractor";
    super(props);
    this.markerClicked = this.markerClicked.bind(this);
    this.state = {
      markers: [],
      places: [],
    };
  }
  componentDidMount() {
    this.props.dispatch(userActions.staticsData());
  }

  markerClicked(marker) {
    console.log("The marker that was clicked is", marker);
  }

  render() {
    let classes = {};
    // const { places } = this.state;
    let { users } = this.props;
    let { staticsData } = users;
    return (
      <>
        <PageTitle title="Dashboard" />
        <Grid container spacing={4}>

          {/* <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              title="Total Workers"
              upperTitle
              disableWidgetMenu={false}
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                  {staticsData ? staticsData.worker : 0}
                </Typography>
              </div>
            </Widget>
          </Grid> */}

          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Box
              sx={{
                backgroundColor: '#8093FE',
                display: 'flex',
                justifyContent: 'space-between',
                padding: 4,
                borderRadius: 4,
              }}
            >
              <div>
                <Typography variant="h5" style={{ color: "#fff", fontWeight: 700 }}>
                  Total Workers
                </Typography>
                <Typography variant="body1" style={{ color: "#FFFFFFB2", fontWeight: 400 }}>
                  {staticsData ? staticsData.worker : 0}
                </Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end', paddingTop: 1 }}>
                <img src="/img/house.png" alt="" style={{ width: '60px', height: '60px' }} />
              </div>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}
Dashboard.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    users,
  };
}
export default connect(mapStateToProps)(Dashboard);
