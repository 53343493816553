import React from "react";
import { Dialog, AppBar, Toolbar, IconButton, Container, CssBaseline, Grid, Typography, } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button } from "../../../../components/Wrappers/Wrappers";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
export default function UpdatePasswordDialog(props) {
  let {
    classes,
    updatePwdformData,
    updateuserCreateModal,
    updatepwdhandleChangeInput,
    updateNewPassChangeInput,
    updatepwdhandleSubmit,
    updatePwdhandleClose,
    newPassword,
  } = props;

  // console.log('updatePwdformData________', updatePwdformData);

  return (
    <>
      {/* <Dialog
        fullScreen
        open={updateuserCreateModal}
        onClose={updatePwdhandleClose}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={updatePwdhandleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Update Password
            </Typography>
            <Button color="inherit" onClick={updatePwdhandleClose}>
              Cancel
            </Button>
          </Toolbar>
        </AppBar>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Update Password
            </Typography>

            <ValidatorForm onSubmit={updatepwdhandleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextValidator
                    label="Password*"
                    id="password"
                    fullWidth
                    onChange={updateNewPassChangeInput}
                    name="password"
                    type="password"
                    value={updatePwdformData.password}
                  />
                </Grid>

                <Grid item xs={12}></Grid>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                className={classes.submit}
              >
                Save
              </Button>
            </ValidatorForm>
          </div>
        </Container>
      </Dialog> */}

      <Dialog open={updateuserCreateModal} onClose={updatePwdhandleClose}>

        <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
          <CloseIcon onClick={updatePwdhandleClose} style={{ backgroundColor: "#8093FE", padding: "4px", cursor: "pointer", transition: "background-color 0.3s", color: "#fff", borderRadius: "100%" }} />
        </div>
        <DialogTitle>Update Password</DialogTitle>
        <DialogContent style={{ width: "100%", minWidth: "360px", height: "100%", overflow: "hidden" }}>
          <ValidatorForm onSubmit={updatepwdhandleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Password*"
                  id="password"
                  fullWidth
                  onChange={updateNewPassChangeInput}
                  name="password"
                  type="password"
                  value={updatePwdformData.password}
                />
              </Grid>

              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: "20px" }}
              className={classes.submit}
            >
              Save
            </Button>
          </ValidatorForm>
        </DialogContent>

        <style>
          {`
@media (min-width: 600px) {
.MuiDialogContent-root {
min-width: 100%;
width: calc(100% - 32px); /* Adjust padding */
}
}

@media (min-width: 400px) {
.MuiDialogContent-root {
min-width: 100%;
width: calc(100% - 16px); /* Adjust padding */
}
}
`}
        </style>
      </Dialog>
    </>
  );
}
