import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { landlordActions } from './';
import { history } from '../_helpers';
import { toast } from 'react-toastify';

export const userActions = {
    staticsData,
    updatePassword,
    login,
    logout,
    addUser,
    uploadImage,
    disableUser,
    deleteUser,
    updateUser,
    getAllUser,
    getUserList,
    loginLandlord,
    forgotPassword,
    forgotUpdatePassword
};
function login(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    history.push({ pathname: '#/app/dashboard' });
                    window.location.reload();
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function forgotPassword(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.forgotPassword(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success('Verification code sent to your email!'));
                    // history.push({ pathname: '/' });
                    // window.location.reload();
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.FORGOT_PASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.FORGOT_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.FORGOT_PASSWORD_FAILURE, error } }
}
function forgotUpdatePassword(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.forgotUpdatePassword(data)
            .then(
                user => {
                    dispatch(success(user));
                    if (user.forgotUpdatePassword.message === "Password change successfully") {
                        dispatch(alertActions.success(user.forgotUpdatePassword.message));
                        props.history.push(`/#/login`)

                    } else {
                        dispatch(alertActions.error(user.forgotUpdatePassword.message));

                    }


                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.OTP_FORGOT_PASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.OTP_FORGOT_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.OTP_FORGOT_PASSWORD_FAILURE, error } }
}
function loginLandlord(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.loginLandlord(data)
            .then(
                user => {
                    dispatch(success(user));
                    history.push({ pathname: '#/app/jobLandlord' });
                    window.location.reload();
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_LANDLORD_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_LANDLORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_LANDLORD_FAILURE, error } }
}
function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}
function getUserList(data) {
    return dispatch => {
        dispatch(request());
        userService.getUserList(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}
function staticsData(data) {
    return dispatch => {
        dispatch(request());
        userService.staticsData(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.STATS1111_REQUEST } }
    function success(users) { return { type: userConstants.STATS1111_SUCCESS, users } }
    function failure(error) { return { type: userConstants.STATS1111_FAILURE, error } }
}

function updatePassword(data) {
    let reqData = {
        "keyWord": "",
        "pageNo": 1,
        "roll_id": 0,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        userService.updatePassword(data)
            .then(

                users => {
                    toast("Password Updated successfully.")
                    dispatch(success(users));
                    dispatch(landlordActions.getLandlordList(reqData));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_PASSWORD_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_PASSWORD_FAILURE, error } }
}

function getAllUser(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllUser(data)
            .then(
                users => {
                    //console.log("users  ",users);
                    let returnResppnse =
                        users.getAllUser ? users.getAllUser.map(user => ({ value: user.id, label: user.firstName + " " + user.lastName })) : [];
                    let newRespnse = { ...users, getAllUser: returnResppnse }
                    dispatch(success(newRespnse))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_ALL_USER_REQUEST } }
    function success(users) { return { type: userConstants.GET_ALL_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_ALL_USER_FAILURE, error } }
}
function addUser(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        userService.addUser(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUserList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_RESTAURANT_USER_REQUEST } }
    function success(users) { return { type: userConstants.ADD_RESTAURANT_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_RESTAURANT_USER_FAILURE, error } }
}
function updateUser(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        userService.updateUser(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUserList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_RESTAURANT_USER_REQUEST } }
    function success(users) { return { type: userConstants.ADD_RESTAURANT_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_RESTAURANT_USER_FAILURE, error } }
}
function disableUser(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id: data.id
    }
    return dispatch => {
        dispatch(request());
        userService.disableUser(maintempdata)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUserList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_RESTAURANT_USER_REQUEST } }
    function success(users) { return { type: userConstants.ADD_RESTAURANT_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_RESTAURANT_USER_FAILURE, error } }
}
function deleteUser(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id: data.id
    }
    return dispatch => {
        dispatch(request());
        userService.deleteUser(maintempdata)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUserList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_RESTAURANT_USER_REQUEST } }
    function success(users) { return { type: userConstants.ADD_RESTAURANT_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_RESTAURANT_USER_FAILURE, error } }
}
function uploadImage(data) {
    return dispatch => {
        userService.uploadImage(data)
            .then(
                uploadImage => {
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}
