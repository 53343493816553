import React, { Component } from "react";
import { Grid, Button, Typography, CssBaseline, TextField, } from "@material-ui/core";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle/PageTitle";
import AddWorkerDialog from "./components/AddWorkerDialog/AddWorkerDialog";
import UpdateWorkerDialog from "./components/UpdateWorkerDialog/UpdateWorkerDialog";
import UpdatePasswordDialog from "./components/UpdatePasswordDialog/UpdatePasswordDialog";
import { confirmAlert } from 'react-confirm-alert';
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import LinearProgress from '@material/react-linear-progress';
import { workerActions, contractorActions, userActions } from "../../_actions";
import { createMuiTheme, MuiThemeProvider, withStyles, } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import styles from "./styles";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';




const theme = createMuiTheme();

class WorkerManagement extends Component {
  constructor(props) {
    document.title = "ATS | Contractor";
    super(props);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleOpenCreateModal = this.handleOpenCreateModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDisable = this.onDisable.bind(this);
    this.updatehandleChangeInput = this.updatehandleChangeInput.bind(this);
    this.updatepwdhandleChangeInput =
      this.updatepwdhandleChangeInput.bind(this);
    this.updatehandleSubmit = this.updatehandleSubmit.bind(this);
    this.updatehandleClose = this.updatehandleClose.bind(this);
    this.updatePwdhandleClose = this.updatePwdhandleClose.bind(this);
    this.updatehandleOpenCreateModal =
      this.updatehandleOpenCreateModal.bind(this);
    this.updatePwdhandleOpenCreateModal =
      this.updatePwdhandleOpenCreateModal.bind(this);
    this.state = {
      markers: [],
      places: [],
      offset: 0,
      size: 10,
      page: 1,
      email: "",
      sortBy: "contractor",
      sortOrder: "1",
      workerCreateModal: false,
      updateworkerCreateModal: false,
      updatePasswordCreateModal: false,
      submitted: false,
      keyWord: "",
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        address: "",
        contact: "",
        roll_id: "",
        parentId: "",
      },
      updateformData: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        address: "",
        contact: "",
        parentId: "",
      },
      updatePwdformData: {
        id: "",
        password: "",
      },
      newPassword: {},

    };
  }
  componentDidMount() {
    let data = {
      keyWord: "",
      pageNo: 1,
      size: this.state.size,
    };
    this.props.dispatch(userActions.staticsData());
    this.props.dispatch(workerActions.getWorkerList(data));
    this.props.dispatch(workerActions.getPackageList(data));
    // this.props.dispatch(workerActions.getCurrentUserPackageByUserId(data));

    // let contractorsData = {
    //   keyWord: "",
    //   pageNo: 1,
    //   roll_id: 1,
    //   // size: this.state.size,
    // };
    // this.props.dispatch(contractorActions.getContractorList(contractorsData));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.workers.addWorkerSuccess) {
      console.log('0000000000000000000');
      return {
        ...nextProps,
        formData: {
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          address: "",
          contact: "",
          roll_id: "",
          parentId: "",
        },
        updatePwdformData: {
          id: "",
          password: "",
        },
        newPassword: {},
        workerCreateModal: false,
        updateworkerCreateModal: false,
        updatePasswordCreateModal: false,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }
  handleClick = (offset, page) => {
    this.setState({ offset, page });
    let data = {
      keyWord: "",
      pageNo: page,
      size: this.state.size,
    };
    this.props.dispatch(workerActions.getWorkerList(data));
  };
  handleSearch(event) {
    this.handleSort = this.handleSort.bind(this);

    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      keyWord: value,
      pageNo: 1,
      size: this.state.size,
    };
    this.props.dispatch(workerActions.getWorkerList(data));
  }
  handleOpenCreateModal() {
    this.setState({ workerCreateModal: true });
  }
  updatehandleOpenCreateModal(data) {
    this.setState({ updateworkerCreateModal: true, updateformData: data });
  }
  updatePwdhandleOpenCreateModal(data) {
    console.log("datadatadatadata", data);
    this.setState({ updatePasswordCreateModal: true, updatePwdformData: data });
  }

  handleClose() {
    this.setState({ workerCreateModal: false });
  }
  updatehandleClose() {
    this.handleSort = this.handleSort.bind(this);

    this.setState({ updateworkerCreateModal: false });
  }
  updatePwdhandleClose() {
    this.setState({ updatePasswordCreateModal: false });
  }
  handleChange = (event) => {
    const email = event.target.value;
    this.setState({ email });
  };
  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
    console.log(event.target.name, event.target.value);
  };
  updatehandleChangeInput = (event) => {
    const { name, value } = event.target;
    const { updateformData } = this.state;
    if (name === "parentId") {
      updateformData["contractor"] = {
        id: value,
      };
    }
    updateformData[name] = value;
    this.setState({ updateformData });
  };
  updatepwdhandleChangeInput = (event) => {
    console.log("eventeventeventevent", event.target.value);
    const { updatePwdformData } = this.state;
    updatePwdformData[event.target.name] = event.target.value;
    this.setState({ updatePwdformData });
  };
  updateNewPassChangeInput = (event) => {
    console.log("eventeventeventevent", event.target.value);
    let { updatePwdformData } = this.state
    updatePwdformData[event.target.name] = event.target.value
    // updatePwdformData = event.target.value;
    this.setState(updatePwdformData);
  };

  handleSort(columnName) {
    let sortOrder = this.state.sortOrder;
    sortOrder = sortOrder === "-1" ? "1" : sortOrder === "1" ? "" : "-1";
    this.setState({ page: 1, sortOrder, sortBy: columnName });
    let data = {
      keyWord: "",
      pageNo: 1,
      size: this.state.size,
    };

    this.props.dispatch(workerActions.getWorkerList(data));
  }

  handleSubmit = () => {
    let reqData = {
      first_name: this.state.formData.firstName,
      last_name: this.state.formData.lastName,
      email: this.state.formData.email,
      password: this.state.formData.password,
      address: this.state.formData.address,
      contact: this.state.formData.mobile,
      roll_id: 2,
      // parentId: this.state.formData.parentId,
    };
    console.log("reqData: ", reqData);
    this.props.dispatch(workerActions.addWorker(reqData));
  };
  updatehandleSubmit = () => {
    let reqData = {
      id: this.state.updateformData.id,
      first_name: this.state.updateformData.first_name,
      last_name: this.state.updateformData.last_name,
      email: this.state.updateformData.email,
      address: this.state.updateformData.address,
      contact: this.state.updateformData.contact,
      // "roll_id": 2
      // parentId: this.state.updateformData.contractor.id,
    };
    console.log("reqData  ", reqData);

    this.props.dispatch(workerActions.updateWorker(reqData));
  };

  updatepwdhandleSubmit = () => {
    let reqData = {
      id: this.state.updatePwdformData.id,
      password: this.state.updatePwdformData.password,
    };
    console.log("reqDatareqaupdatepwdhandleSubmit  ", reqData);

    this.props.dispatch(workerActions.updatePassword(reqData));
  };

  onChangeFile(event) {
    this.props.dispatch(
      workerActions.uploadImage(
        event.target.files[event.target.files.length - 1],
      ),
    );
  }
  onRowClick(data) {
    this.props.history.push(`/app/restaurants/${data.id}`);
  }
  onDeleteClick(data) {
    console.log(data);
    let tempdata = {
      "id": data.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size,
    };
    console.log("asdf :: ", tempdata);
    this.props.dispatch(workerActions.deleteWorker(tempdata));
  }

  buyPackage = (data) => {
    console.log('datadatadatadatadata', data);
    let tempdata = {
      "packageId": data.id,
    }
    let paginationdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    confirmAlert({
      title: 'Confirm to buy Package?',
      message: `Are you sure to buy ${data.packName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(workerActions.buyPackage(tempdata, paginationdata))
        },
        {
          label: 'No'
        }
      ]
    });
  }

  // onDeleteClick = (data) => {
  //   let datatemp = {
  //     "id": data.id,
  //   }
  //   let paginationdata = {
  //     "keyWord": "",
  //     "pageNo": 1,
  //     "size": this.state.size
  //   }
  //   confirmAlert({


  //     title: 'Confirm to disable User?',
  //     message: `Are you sure to delete ${data.name}?`,
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => this.props.dispatch(workerActions.deleteWorker(paginationdata, datatemp))
  //       },
  //       {
  //         label: 'No'
  //       }
  //     ]
  //   });
  // }


  onDisable(data) {
    let tempdata = {
      resourceId: data.id,
      resourceType: "user",
      value: !data.active,
    };
    console.log('tempdata_____________', tempdata, "             data__________", data);
    this.props.dispatch(workerActions.disableWorker(tempdata));
  }

  render() {
    let { workers, contractors, classes, users } = this.props;
    let { items, total, packItems, currentPack } = workers;
    let { staticsData } = users;

    console.log('RENDER_currentPackcurrentPack', currentPack);

    return (
      <>


        <PageTitle title="Workers" />

        {/* <Grid container spacing={3} style={{ marginBottom: "15px" }} >
          {packItems && packItems.length > 0 ? packItems.map((element) => (<> <Grid item xs={4}>
            <Card>
              <CardContent>
              
                {currentPack && currentPack.packageId && currentPack.packageId.id == element.id ?
                  <h3>{staticsData ? staticsData.worker : ""}/{currentPack.packageId.limit}</h3>
                  :
                  <div>
                    <span > Limit : {element.limit}</span>
                  </div>

                }
              </CardContent>
              {currentPack && currentPack.packageId && currentPack.packageId.id == element.id ?
                null :
                <CardActions>

                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "28px", marginLeft: "15px" }}
                    onClick={() => { this.buyPackage(element) }}
                  >
                    Upgrade
                  </Button>
                </CardActions>
              }
            </Card>
          </Grid></>)) : null}


        </Grid> */}
        <Grid item xs={4}> <LinearProgress
          buffer={0.9}
          progress={0.8}
        /></Grid>

        <Grid container>
          <Grid item xs={12} md={12}>
            <Widget>
              <Grid container>
                <Grid item>
                  <TextField
                    id="standard-search"
                    label="Search Name"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    // className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item sm />
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleOpenCreateModal()}
                    style={{ marginTop: "28px", marginLeft: "15px", color: "#fff", fontWeight: 700 }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {items && items.length > 0 ? (
                <>
                  <Table
                    data={items}
                    sortOrder={this.state.sortOrder}
                    offset={this.state.offset}
                    onRowClick={this.onRowClick}
                    handleSort={this.handleSort}
                    updatehandleOpenCreateModal={
                      this.updatehandleOpenCreateModal
                    }
                    updatePwdhandleOpenCreateModal={
                      this.updatePwdhandleOpenCreateModal
                    }
                    onDeleteClick={this.onDeleteClick}
                    onDisable={this.onDisable}
                  />
                </>
              ) : (
                <Typography>Data not found.</Typography>
              )}
              {total && total > 10 ? (
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <Pagination
                    limit={this.state.size}
                    offset={this.state.offset}
                    total={total}
                    onClick={(e, offset, page) =>
                      this.handleClick(offset, page)
                    }
                    style={{ marginTop: "20px", textAlign: "end" }}
                  />
                </MuiThemeProvider>
              ) : null}
            </Widget>
          </Grid>
        </Grid>
        <AddWorkerDialog
          workerCreateModal={this.state.workerCreateModal}
          classes={classes}
          formData={this.state.formData}
          handleSubmit={this.handleSubmit}
          handleChangeInput={this.handleChangeInput}
          handleClose={this.handleClose}
          contractors={contractors.items}
        />
        <UpdateWorkerDialog
          updateuserCreateModal={this.state.updateworkerCreateModal}
          classes={classes}
          updateformData={this.state.updateformData}
          parentId={this.state.updateformData.created_by}
          updatehandleSubmit={this.updatehandleSubmit}
          updatehandleChangeInput={this.updatehandleChangeInput}
          updatehandleClose={this.updatehandleClose}
          contractors={contractors.items}
        />
        <UpdatePasswordDialog
          updateuserCreateModal={this.state.updatePasswordCreateModal}
          classes={classes}
          updatePwdformData={this.state.updatePwdformData}
          newPassword={this.state.newPassword}
          updatepwdhandleSubmit={this.updatepwdhandleSubmit}
          updatepwdhandleChangeInput={this.updatepwdhandleChangeInput}
          updateNewPassChangeInput={this.updateNewPassChangeInput}
          updatePwdhandleClose={this.updatePwdhandleClose}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { workers, contractors, users } = state;
  return {
    workers,
    contractors,
    users
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(WorkerManagement)),
);
