import { staffConstants } from "../_constants";
import { staffService } from "../_services";
import { alertActions, loaderActions } from "./";
import { history } from "../_helpers";
export const staffActions = {
  login,
  logout,
  addStaff,
  updatePassword,
  uploadImage,
  disableStaff,
  deleteStaff,
  updateStaff,
  getAllStaff,
  getStaffList,
  getIsList,
  getInternalStaffByLandLord,
  // getContractorByLandord,
  getInternalStaffLandLordList,
  addStaffLandlord,
  updateStaffLandlord,
  disableStaffLandlord,
  updatePasswordLandlord,
  getInternalStaffByJob
};
function login(data) {
  return (dispatch) => {
    dispatch(request({ data }));
    staffService.login(data).then(
      (staff) => {
        dispatch(success(staff));
        history.push({ pathname: "#/app/dashboard" });
        window.location.reload();
      },
      (error) => {
        console.log("errorerror ", error);
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(staff) {
    return { type: staffConstants.LOGIN_REQUEST, staff };
  }
  function success(staff) {
    return { type: staffConstants.LOGIN_SUCCESS, staff };
  }
  function failure(error) {
    return { type: staffConstants.LOGIN_FAILURE, error };
  }
}
function logout() {
  staffService.logout();
  return { type: staffConstants.LOGOUT };
}
function getStaffList(data) {
  return (dispatch) => {
    dispatch(request());
    staffService.getStaffList(data).then(
      (staffs) => dispatch(success(staffs)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: staffConstants.GETALL_REQUEST };
  }
  function success(staffs) {
    return { type: staffConstants.GETALL_SUCCESS, staffs };
  }
  function failure(error) {
    return { type: staffConstants.GETALL_FAILURE, error };
  }
}
function getIsList(data) {
  return (dispatch) => {
    dispatch(request());
    staffService.getIsList(data).then(
      (staffs) => dispatch(success(staffs)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: staffConstants.GETALL_IS_REQUEST };
  }
  function success(staffs) {
    console.log("Action_od_IS",staffs);
    return { type: staffConstants.GETALL_IS_SUCCESS, staffs };
  }
  function failure(error) {
    return { type: staffConstants.GETALL_IS_FAILURE, error };
  }
}
function getInternalStaffLandLordList(data) {
  return (dispatch) => {
    dispatch(request());
    staffService.getInternalStaffLandLordList(data).then(
      (staffs) => dispatch(success(staffs)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: staffConstants.GETALL_ITNL_STAFF_LANDLOARD_LIST_REQUEST };
  }
  function success(staffs) {
    return { type: staffConstants.GETALL_ITNL_STAFF_LANDLOARD_LIST_SUCCESS, staffs };
  }
  function failure(error) {
    return { type: staffConstants.GETALL_ITNL_STAFF_LANDLOARD_LIST_FAILURE, error };
  }
}
function getInternalStaffByJob(data) {
  return (dispatch) => {
    dispatch(request());
    staffService.getInternalStaffByJob(data).then(
      (staffs) => dispatch(success(staffs)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: staffConstants.GETALL_ITNL_STAFF_JOB_REQUEST };
  }
  function success(staffs) {
    return { type: staffConstants.GETALL_ITNL_STAFF_JOB_SUCCESS, staffs };
  }
  function failure(error) {
    return { type: staffConstants.GETALL_ITNL_STAFF_JOB_FAILURE, error };
  }
}
// function getContractorByLandord(data) {
//   return (dispatch) => {
//     dispatch(request());
//     staffService.getContractorByLandord(data).then(
//       (staffs) => dispatch(success(staffs)),
//       (error) => {
//         dispatch(alertActions.error(error));
//         dispatch(failure(error));
//       },
//     );
//   };
//   function request() {
//     return { type: staffConstants.GET_CONTRACTOR_BY_LANDLORD_REQUEST };
//   }
//   function success(staffs) {
//     return { type: staffConstants.GET_CONTRACTOR_BY_LANDLORD_SUCCESS, staffs };
//   }
//   function failure(error) {
//     return { type: staffConstants.GET_CONTRACTOR_BY_LANDLORD_FAILURE, error };
//   }
// }
function getInternalStaffByLandLord(data) {
  return (dispatch) => {
    dispatch(request());
    staffService.getInternalStaffByLandLord(data).then(
      (staffs) => dispatch(success(staffs)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: staffConstants.GETALL_ITNL_STAFF_LANDLOARD_REQUEST };
  }
  function success(staffs) {
    return { type: staffConstants.GETALL_ITNL_STAFF_LANDLOARD_SUCCESS, staffs };
  }
  function failure(error) {
    return { type: staffConstants.GETALL_ITNL_STAFF_LANDLOARD_FAILURE, error };
  }
}
function getAllStaff(data) {
  return (dispatch) => {
    dispatch(request());
    staffService.getAllStaff(data).then(
      (staffs) => {
        //console.log("staffs  ",staffs);
        let returnResppnse = staffs.getAllStaff
          ? staffs.getAllStaff.map((staff) => ({
            value: staff.id,
            label: staff.firstName + " " + staff.lastName,
          }))
          : [];
        let newRespnse = { ...staffs, getAllStaff: returnResppnse };
        dispatch(success(newRespnse));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: staffConstants.GET_ALL_STAFF_REQUEST };
  }
  function success(staffs) {
    return { type: staffConstants.GET_ALL_STAFF_SUCCESS, staffs };
  }
  function failure(error) {
    return { type: staffConstants.GET_ALL_STAFF_FAILURE, error };
  }
}
function updatePasswordLandlord(data) {
  let reqData = {
    keyWord: "",
    pageNo: 1,
    roll_id: 3,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    staffService.updatePasswordLandlord(data).then(
      (users) => {
        // toast("Password Updated successfully.")
        dispatch(alertActions.success("Password Updated successfully."));
        dispatch(success(users));
        dispatch(staffActions.getInternalStaffByLandLord(reqData));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: staffConstants.UPDATE_INTERNAL_STAFF_PASSWORD_LANDLORD_REQUEST };
  }
  function success(users) {
    return {
      type: staffConstants.UPDATE_INTERNAL_STAFF_PASSWORD_LANDLORD_SUCCESS,
      users,
    };
  }
  function failure(error) {
    return {
      type: staffConstants.UPDATE_INTERNAL_STAFF_PASSWORD_LANDLORD_FAILURE,
      error,
    };
  }
}
function updatePassword(data) {
  let reqData = {
    keyWord: "",
    pageNo: 1,
    roll_id: 3,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    staffService.updatePassword(data).then(
      (users) => {
        // toast("Password Updated successfully.")
        dispatch(alertActions.success("Password Updated successfully."));
        dispatch(success(users));
        dispatch(staffActions.getStaffList(reqData));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: staffConstants.UPDATE_INTERNAL_STAFF_PASSWORD_REQUEST };
  }
  function success(users) {
    return {
      type: staffConstants.UPDATE_INTERNAL_STAFF_PASSWORD_SUCCESS,
      users,
    };
  }
  function failure(error) {
    return {
      type: staffConstants.UPDATE_INTERNAL_STAFF_PASSWORD_FAILURE,
      error,
    };
  }
}
function addStaffLandlord(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    roll_id: 3,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    staffService.addStaffLandlord(data).then(
      (staffs) => {
        dispatch(success(staffs));
        dispatch(this.getInternalStaffByLandLord(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: staffConstants.ADD_STAFF_LANDLORD_REQUEST };
  }
  function success(staffs) {
    return { type: staffConstants.ADD_STAFF_LANDLORD_SUCCESS, staffs };
  }
  function failure(error) {
    return { type: staffConstants.ADD_STAFF_LANDLORD_FAILURE, error };
  }
}
function addStaff(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    roll_id: 3,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    staffService.addStaff(data).then(
      (staffs) => {
        dispatch(success(staffs));
        dispatch(this.getStaffList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: staffConstants.ADD_RESTAURANT_STAFF_REQUEST };
  }
  function success(staffs) {
    return { type: staffConstants.ADD_RESTAURANT_STAFF_SUCCESS, staffs };
  }
  function failure(error) {
    return { type: staffConstants.ADD_RESTAURANT_STAFF_FAILURE, error };
  }
}
function updateStaffLandlord(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    size: 10,
    roll_id: 3,
  };
  return (dispatch) => {
    dispatch(request());
    staffService.updateStaffLandlord(data).then(
      (staffs) => {
        dispatch(success(staffs));
        dispatch(this.getInternalStaffByLandLord(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: staffConstants.UPDATE_STAFF_LANDLORD_REQUEST };
  }
  function success(staffs) {
    return { type: staffConstants.UPDATE_STAFF_LANDLORD_SUCCESS, staffs };
  }
  function failure(error) {
    return { type: staffConstants.UPDATE_STAFF_LANDLORD_FAILURE, error };
  }
}
function updateStaff(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    size: 10,
    roll_id: 3,
  };
  return (dispatch) => {
    dispatch(request());
    staffService.updateStaff(data).then(
      (staffs) => {
        dispatch(success(staffs));
        dispatch(this.getInternalStaffByLandLord(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: staffConstants.ADD_RESTAURANT_STAFF_REQUEST };
  }
  function success(staffs) {
    return { type: staffConstants.ADD_RESTAURANT_STAFF_SUCCESS, staffs };
  }
  function failure(error) {
    return { type: staffConstants.ADD_RESTAURANT_STAFF_FAILURE, error };
  }
}
function disableStaffLandlord(data) {
  const { value } = data;
  // let tempdata = {
  //   keyWord: "",
  //   pageNo: 1,
  //   size: 10,
  // };
  return (dispatch) => {
    dispatch(loaderActions.startLoader());
    dispatch(request());
    staffService.disableStaffLandlord(data).then(
      (staffs) => {
        dispatch(
          alertActions.success(
            `User ${value ? "enabled" : "disabled"} successfully`,
          ),
        );
        // dispatch(success(staffs));
        // dispatch(this.getStaffList(tempdata));
        dispatch(loaderActions.stopLoader());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
        dispatch(loaderActions.stopLoader());
      },
    );
  };
  function request() {
    return { type: staffConstants.ADD_RESTAURANT_STAFF_REQUEST };
  }
  // function success(staffs) {
  //   return { type: staffConstants.ADD_RESTAURANT_STAFF_SUCCESS, staffs };
  // }
  function failure(error) {
    return { type: staffConstants.ADD_RESTAURANT_STAFF_FAILURE, error };
  }
}
function disableStaff(data) {
  const { value } = data;
  // let tempdata = {
  //   keyWord: "",
  //   pageNo: 1,
  //   size: 10,
  // };
  return (dispatch) => {
    dispatch(loaderActions.startLoader());
    dispatch(request());
    staffService.disableStaff(data).then(
      (staffs) => {
        dispatch(
          alertActions.success(
            `User ${value ? "enabled" : "disabled"} successfully`,
          ),
        );
        // dispatch(success(staffs));
        // dispatch(this.getStaffList(tempdata));
        dispatch(loaderActions.stopLoader());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
        dispatch(loaderActions.stopLoader());
      },
    );
  };
  function request() {
    return { type: staffConstants.ADD_RESTAURANT_STAFF_REQUEST };
  }
  // function success(staffs) {
  //   return { type: staffConstants.ADD_RESTAURANT_STAFF_SUCCESS, staffs };
  // }
  function failure(error) {
    return { type: staffConstants.ADD_RESTAURANT_STAFF_FAILURE, error };
  }
}
function deleteStaff(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    size: 10,
  };
  let maintempdata = {
    id: data.id,
  };
  return (dispatch) => {
    dispatch(request());
    staffService.deleteStaff(maintempdata).then(
      (staffs) => {
        dispatch(success(staffs));
        dispatch(this.getStaffList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: staffConstants.ADD_RESTAURANT_STAFF_REQUEST };
  }
  function success(staffs) {
    return { type: staffConstants.ADD_RESTAURANT_STAFF_SUCCESS, staffs };
  }
  function failure(error) {
    return { type: staffConstants.ADD_RESTAURANT_STAFF_FAILURE, error };
  }
}
function uploadImage(data) {
  return (dispatch) => {
    staffService.uploadImage(data).then(
      (uploadImage) => {
        dispatch(success(uploadImage));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function success(uploadImage) {
    return { type: staffConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage };
  }
  function failure(error) {
    return { type: staffConstants.FILE_UPLOAD_STATUS_FAILURE, error };
  }
}
